import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import CTABlock from "../components/blocks/cta_block"
import Loadable from "react-loadable"

const loader = () => <div>Loading...</div>

const BulletinBlock = Loadable({
  loader: () => import("../components/blocks/bulletin_block"),
  loading: loader,
})

const OfficialsPage = ({ data }) => {
  return (
    <>
      <Layout
        isContent={true}
        subMenu={data.wagtail.page.children}
        button={data.wagtail.page.button}
        parent={"officials"}
        siteTitle={"Officials"}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{data.wagtail.page.title}</h1>
                <p>{data.wagtail.page.descriptionSimple}</p>
                <div className="clib-area">
                  <div className="club-img full-width-image">
                    <img src={data.wagtail.page.feedImage.src} alt="swim" />
                  </div>
                </div>
              </div>
            </div>
            {data.wagtail.page.bodySafe && (
              <div className="row">
                <div className="col-12">
                  <div
                    className="c-title-content mb-80"
                    dangerouslySetInnerHTML={{
                      __html: data.wagtail.page.bodySafe,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <BulletinBlock />

        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 0 && (
            <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
          )}

        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 1 && (
            <CTABlock data={data.wagtail.page.fullContent[1]}></CTABlock>
          )}
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    wagtail {
      page(slug: "officials") {
        ... on OfficialsPage {
          descriptionSimple
          id
          title
          body
          bodySafe
          feedImage {
            src
            height
            width
          }
          children {
            id
            title
            slug
            ... on SimplePage {
              live
              isProtected
            }
            ... on PositionsPage {
              live
              isProtected
            }
            ... on OfficialPage {
              live
              isProtected
            }
            ... on BulletinsPage {
              live
              isProtected
            }
          }
          button {
            text
            name
            url
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default OfficialsPage
